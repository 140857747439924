import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Container from '../components/container/container';
import LayoutContent from '../components/layout/layout-content';
import styles from './cp-page-simple.module.scss';
import { useMedia } from '../hooks/useMedia';

const ContenPageSimple = ({ data }) => {
  const {
    html,
    frontmatter: { title, titleMobile },
  } = data.markdownRemark;

  const isPhone = useMedia(['(max-width: 767px)'], [true], false);

  return (
    <LayoutContent>
      <SEO title={`${title}`} />
      <div className={styles.background} />
      <Container className={styles.title}>
        {isPhone ? (
          <h1 className={styles.firstHeading}>{titleMobile || title}</h1>
        ) : (
          <h1 className={styles.firstHeading}>{title}</h1>
        )}
      </Container>
      <Container className={styles.mainContent} dangerouslySetInnerHTML={{ __html: html }} />
    </LayoutContent>
  );
};

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleMobile
        path
      }
      html
    }
  }
`;

export default ContenPageSimple;
