import React, { useState } from 'react';

import '../../styles/main.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import { CookiesSnackbar } from '../cookies/cookies';
import { CookiesShowContext } from '../cookies/cookies-context/cookies-context';
import styles from './layout-content.module.scss';
import Container from '../container/container';

const LayoutContent = ({ noMargin = false, showTransparentBackground = false, showBanner = false, children }) => {
  const [showCookies, setShowCookies] = useState(false);
  return (
    <CookiesShowContext.Provider
      value={{
        showCookies,
        setShowCookies,
      }}
    >
      <div style={{ position: 'relative' }}>
        <Header showTransparentBackground={showTransparentBackground} />
        {showBanner && (
          <Container variant="div" className={styles.alert}>
            <div>
              <h3>ACHTUNG!</h3>
              <p>
                Aktuell sind mehrere gefälschte Stellenanzeigen auf diversen Jobportalen in unserem Namen zu finden. Die
                Betrüger geben sich als Evelan GmbH aus. Wir gehen rechtlich dagegen vor, um diese Anzeigen
                schnellstmöglich entfernen zu lassen. Bitte bewerben Sie sich nicht auf diese Stellenanzeigen und seien
                Sie vorsichtig mit Ihren Daten.
              </p>
              <p>
                Außerdem scheinen die Betrüger die Domain <strong>eve-lan.de</strong> zu besitzen. Unser Unternehmen hat
                nichts mit dieser Domain zu tun. Wir distanzieren uns von allen Inhalten auf <strong>eve-lan.de</strong>
                .
              </p>
              <p>
                Evelan GmbH ist ausschließlich unter der Domain: <strong>evelan.de</strong> zu finden.
              </p>
            </div>
          </Container>
        )}
        <main className={noMargin && 'no-spacing'}>{children}</main>
        <Footer />
        <CookiesSnackbar />
      </div>
    </CookiesShowContext.Provider>
  );
};

export default LayoutContent;
