import React, { useEffect, useState } from 'react';
import Container from '../container/container';
import styles from './header.module.scss';
import { graphql, Link, useStaticQuery } from 'gatsby';
import logoNormal from '../../images/logo/evelan-gmbh.svg';
import { PhoneButton } from '../button/phone-button';
import CtaButton from '../button/cta-button';
import clsx from 'clsx';

const Header = ({ showTransparentBackground = false }) => {
  const {
    site: {
      siteMetadata: { menuLinks },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  const [scrolled, setScrolled] = useState();

  useEffect(() => {
    const handleScroll = (_) => {
      if (window.pageYOffset > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return (_) => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled]);

  return (
    <header
      className={clsx(
        styles.header,
        scrolled && styles.headerScrolled,
        showTransparentBackground && styles.transparentBG
      )}
    >
      <Container className={styles.container} variant="div">
        <Link className={styles.logo} to="/">
          <img src={logoNormal} alt="Evelan GmbH" />
        </Link>
        <input type="checkbox" id="nav-toggle" name="nav-toggle" aria-label="Menu" className={styles.navToggle} />
        <nav>
          <ul>
            {menuLinks.map((link) => (
              <li key={link.name}>
                <Link to={link.link} activeClassName={styles.selected}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <CtaButton
          cta={{ to: '/projektanfrage/#inquiry-header', label: 'Jetzt Anfragen' }}
          useIconOnMobile={true}
          className={styles.ctaWrapper}
        />
        <div className={styles.ctaPhone}>
          <PhoneButton phone="+494088215940">+49 40 882159 40</PhoneButton>
        </div>
        <label htmlFor="nav-toggle" className={styles.navToggleLabel}>
          <span> </span>
        </label>
      </Container>
    </header>
  );
};

export default Header;
