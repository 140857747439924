import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import ArrowRightIcon from '../../images/icons/arrow_right_icon.svg';
import Container from '../container/container';
import Img from 'gatsby-image';
import styles from './footer.module.scss';
import { CookiesShowContext } from '../cookies/cookies-context/cookies-context';

const Footer = () => {
  const {
    site: {
      siteMetadata: {
        footerMenuLinks,
        socialMediaLinks,
        slogan,
        contact: { name, street, zip, location, phone },
      },
    },
    portrait: {
      childImageSharp: { fixed: portrait },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            slogan
            contact {
              name
              street
              zip
              location
              phone
              meetingLink
            }
            footerMenuLinks {
              name
              link
            }
            socialMediaLinks {
              name
              link
            }
          }
        }
        portrait: file(relativePath: { eq: "portrait_as.jpg" }) {
          childImageSharp {
            fixed(width: 80, height: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );
  const { setShowCookies } = useContext(CookiesShowContext);

  return (
    <footer className={styles.footer}>
      <Container variant="div" className={styles.footerWrapper}>
        <div className={styles.slogan}>{slogan}</div>
        <nav className={styles.footerNav}>
          <ul>
            {footerMenuLinks.map((link) => (
              <li key={link.name}>
                <Link to={link.link} activeClassName={styles.selected}>
                  {link.name}
                </Link>
              </li>
            ))}
            <li>
              <button onClick={() => setShowCookies(true)} type="button">
                Cookies
              </button>
            </li>
          </ul>
        </nav>
      </Container>
      <Container variant="div" className={styles.contactDetailsWrapper}>
        <div>
          <p className={styles.smallHeader}>Office</p>
          <p className={styles.footerDetails}>
            {name}
            <br /> {street}
            <br /> {`${zip} ${location}`}
          </p>
        </div>
        <div className={styles.contactDetails}>
          <p className={styles.smallHeader}>Ihr Anliegen</p>
          <p className={styles.footerDetails}>{phone}</p>
          <p className={styles.contactFonts}>
            <a href={`tel:${phone.replace(/ /g, '')}`}>
              Allgemeine Anfragen <img src={ArrowRightIcon} width={14} height={11} alt="Pfeil" role="presentation" />
            </a>
          </p>
        </div>
        <div className={styles.contactDetailsWithImageWrapper}>
          <div className={styles.contactDetailsCeo}>
            <p className={styles.smallHeader}>1-zu-1 Beratung</p>
            <p className={styles.footerDetails}>Andreas Straub</p>
            <p className={styles.contactFonts}>
              <Link to="/projektanfrage/#inquiry-header" rel="noopener noreferrer" target="_blank">
                Lassen Sie uns sprechen{' '}
                <img src={ArrowRightIcon} width={14} height={11} alt="Pfeil" role="presentation" />
              </Link>
            </p>
          </div>
          <div className={styles.imgContainer}>
            <Img fixed={portrait} width={80} height={80} alt="CEO" className={styles.portrait} />
          </div>
        </div>
      </Container>
      <Container variant="div" className={styles.footerWrapperBelow}>
        <nav className={styles.footerNavBelow}>
          <ul>
            {socialMediaLinks.map((link) => (
              <li key={link.name}>
                <a href={link.link} rel="noopener noreferrer" target="_blank">
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.copyright}>
          © {name} | {new Date().getFullYear()}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
